import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private requestCount: number;
  constructor(private router: Router,
    private spinner: NgxSpinnerService) {
    this.requestCount = 0;
   }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestCount++;
    if (this.requestCount === 1) {
      this.spinner.show();
    }
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {

        // if ignored statuses are set
        // and returned status matched 
        if (e && e.status === 401) {
          // rethrow error to be catched locally
          alert('หมดเวลาล็อคอิน กรุณาล็อคอินใหม่')
          localStorage.removeItem('token');
          this.router.navigate(['/login']);
          // return throwError(() => e);
        }

        // process error...
        console.log('error interceptor !!', e);
        return throwError(e);
      }),
      finalize(() => {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.spinner.hide();
        }
      })
    );
  }
}
