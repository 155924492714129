import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { FormControl, FormGroup } from '@angular/forms';
// import { NavbarComponent } from '../navbar/navbar.component';


export interface LoginComponent1 {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

enum Storage {
  token = 'token',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  constructor(private router: Router,
    private service: ServiceService) { }

  ngOnInit() {
    if (localStorage.getItem('token') !== null) {
      this.router.navigate(['/'])
    }
    this.initialFrom();
  }

  initialFrom() {
    this.form = new FormGroup({
      username: new FormControl(null),
      password: new FormControl(null),
    });
  }

  onGoToMain() {
    let data = {
      username: this.form.controls.username.value,
      password: this.form.controls.password.value
    }

    return new Promise<any>((resolve, reject) => {
      this.service.login(data).subscribe(async (resp) => {
        if (resp && resp.token) {
          this.setToken(resp.token);
          this.router.navigate(['/']);
          resolve(true);
        }
      },
        (errorRes) => {
          if (errorRes && errorRes.message) {
            alert(errorRes.message);
            return;
          }
        });
    });
  }

  setToken(token: string) {
    localStorage.setItem(Storage.token, token);
  }

  getToken() {
    return localStorage.getItem(Storage.token);
  }

}
