import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-comfirm',
  templateUrl: './popup-comfirm.component.html',
  styleUrls: ['./popup-comfirm.component.css']
})
export class PopupComfirmComponent implements OnInit {
  @Input() message: string;
  @Input() number: number;
  @Input() flag: boolean;
  @Input() status: string;
  @Input() imageUrl: string;
  sum: string;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.sum = this.number ? this.localeString(this.number) : null;
  }

  public close() {
    this.activeModal.close(false);
  }

  public save() {
    this.activeModal.close(true);
  }

  localeString(data: number) {
    let points = data.toLocaleString('en-US');
    return points;
  }

}
