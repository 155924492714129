import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  url: String = 
  // `https://mgold.ddns.net:8080`;
  `https://mgold.ddns.net/backend`;
  // `http://localhost:8080`;
  // `https://b4e6-171-96-174-101.ngrok-free.app`;


  constructor(private http: HttpClient) { }

  login(data): Observable<any> {
    const localUrl = this.url + `/auth/login`;
    return this.http.post<any>(localUrl, data);
  }

  save(data): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, data, { headers });
  }

  installments(date): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/installments/date/${date}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  getViewDetail(customerId: number, dueDate: string): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/${customerId}/${dueDate}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  viewInstallmentl(transactionId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/installments/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  delete(transactionId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.delete<any>(localUrl, { headers });
  }

  customer(): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/customers`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  getListCustomer(): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/customers`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  getCustomer(customerId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/customer/${customerId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  close(transactionId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/close/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, null, { headers });
  }

  open(transactionId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/open/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, null, { headers });
  }


  prepaidTotal(transactionId: number, installmentId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/prepaid/${transactionId}/${installmentId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, null, { headers });
  }

  search(startDate: string, endDate: string, customerId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/search/${startDate}/${endDate}/${customerId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  prepaid(transactionId, data: any): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/prepaid/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, data, { headers });
  }

  searchCus(customerId: number, startDate: string, endDate: string): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/search1/${customerId}/${startDate}/${endDate}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  logout(token): Observable<any> {
    const localUrl = this.url + `/auth/logout`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, token, { headers });
  }

  deposit(transactionId: number, transaction: any): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/deposit/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, transaction, { headers });
  }

  deleteCustomers(id): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/customers/${id}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.delete<any>(localUrl, { headers });
  }

  offline(transactionId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/offline/${transactionId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post<any>(localUrl, null, { headers });
  }

  searchOffline(customerId: number): Observable<any> {
    let token = localStorage.getItem('token');
    const localUrl = this.url + `/api/transactions/offline/${customerId}`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.get<any>(localUrl, { headers });
  }

  uploadFile(file): Observable<any> {
    let token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);
    const localUrl = this.url + `/api/images/upload`;
    const headers = { 'Authorization': `Bearer ${token}` };
    return this.http.post(localUrl, formData, { headers });
  }
}
