import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComfirmComponent } from '../popup-comfirm/popup-comfirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Big } from 'big.js';
import { ServiceService } from 'src/app/service/service.service';

@Component({
  selector: 'app-view-installment',
  templateUrl: './view-installment.component.html',
  styleUrls: ['./view-installment.component.css']
})
export class ViewInstallmentComponent implements OnInit {
  @Input() installment: any;
  @Input() isCheckStatus: string;
  @Input() transactionId: number;
  @Input() getData: any;
  @Input() cfSave: boolean;
  page = 1;
  pageSize = 2;
  dataSource: any[] = [];
  date: string;
  listData: any;
  installmentid: any[] =[];
  constructor(private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private service: ServiceService) { }


  ngOnInit() {
    this.dataSource = this.installment;
    this.date = this.formatDateInput(new Date);
    this.listApi();
  }

  public close() {
    this.activeModal.close(false);
  }

  async pressPay(index, event: any) {
    let item = 0;
    this.dataSource.forEach((element, i) => {
      if (index <= i) {
        item = item + parseInt(this.formatNumber(element.installmentAmt));
      }
    });
    const popup = await this.onClickDetailDialog(item, index + 1, event);
    if (popup) {
      return new Promise<any>((resolve, reject) => {
        this.service.prepaidTotal(this.transactionId, event.installmentId).subscribe(resp => {
          if (resp && resp.statusCode === 200) {
            // this.dataMain.status === 'cus' ? this.getCustomer() : this.getData();
            alert("สำเร็จ");
            this.close();
            resolve(true);
          }
        },
          (errorRes) => {
            if (errorRes.error && errorRes.error.errorDesc) {
              return;
            }
          });
      });
    }

  }

  formatNumber(value: string) {
    if (value && value !== '') {
      let parts = String(value).replace(/[^-\d.]/g, '').split('.');
      parts[0] = new Big(parts[0].padStart(1, '0')).toString();
      return parts[0];
    }
  }

  async onClickDetailDialog(number: number, index: number, event: any) {
    const modalRef = this.modalService.open(PopupComfirmComponent, {
      backdrop: 'static', keyboard: false
    });
    let message = 'ต้องการจ่ายล่วงหน้าตั้งแต่งวด ' + index + ' วันที่ ' + this.isoStringToDate(this.formatDateInput2(event.dueDate)) + ' เป็นต้นไป ยอดปิด';
    let flag = true;
    let status =  this.isCheckStatus;
    this.setModalRef(modalRef, { message, number, flag, status });
    return modalRef.result;
  }

  setModalRef(modalRef: any, modalInfo: any) {
    for (const item of Object.keys(modalInfo)) {
      modalRef.componentInstance[item] = modalInfo[item];
    }
    return modalRef;
  }

  formatDateInput(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }

  formatDateInput2(date: string) {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  isoStringToDate(data: string) {
    var date = new Date(data);
    return date.toLocaleDateString('th-TH', {
      // weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  async saveAsTextFile() {
    let item = this.getData;
    let fileText = '';
    fileText += 'รายการบัญชี <BR> ------------ <BR>';
    fileText += 'ชื่อลูกค้า: ' + item.customerName + '<BR>';
    fileText += 'วันที่: ' + this.isoStringToDate(this.formatDateInput2(item.createDate)) + '<BR>';
    fileText += 'รายการ: ' + item.description + '<BR>';
    fileText += 'ประเภท: ' + this.listData.transactionType.find(event => event.transactionTypeId ===  item.transactionType).transactionTypeDesc + '<BR>';
    fileText += 'เงินต้น: ' + this.localeString(item.principle) + '<BR>';
    fileText += 'ดาวน์/หักดอก: ' + item.firstDownAmt + '<BR>';
    fileText += 'คงเหลือผ่อน: ' + this.localeString(item.remaining) + '<BR>';
    fileText += 'อัตราดอกเบี้ย: ' + item.interestRate + '%<BR>';
    fileText += 'ยอดรวม: ' + this.localeString(item.total) + '<BR>';
    fileText += 'จ่ายแบบ: ' + this.listData.paymentType.find(event => event.paymentTypeId === item.paymentType).paymentTypeDesc + (item.paymentType === 1 ? ' ' + item.daily + ' วัน' : '') + '<BR>';
    fileText += 'จำนวนงวด: ' + item.contractPeriod + '<BR>';
    fileText += 'ผ่อนงวดละ: ' + this.localeString(item.installmentAmt) + '<BR>';
    fileText += 'วันที่เริ่มผ่อน: ' + this.isoStringToDate(this.formatDateInput2(item.firstDueDate)) + '<BR>';
    fileText += '------------ <BR> รายการจ่ายค่างวด <BR> ------------ <BR>';
    this.installment.forEach(async (item, i) => {
      fileText += '(' + (i + 1) + ') ' + this.isoStringToDate(this.formatDateInput2(item.dueDate)) + ' = ' + this.localeString(item.installmentAmt) + '<BR>'
    })
    let w = null;
    setTimeout(() => {
      w = window.open("", "_blank");
      w.document.write(fileText);
    });
    this.close();
  }

  localeString(data: number) {
    let points = data.toLocaleString('en-US');
    return points;
  }

  listApi() {
    this.listData = {
      "interestType": [
        {
          "interestTypeId": 1,
          "interestTypeDesc": "รายปี",
          "seqNo": 1
        },
        {
          "interestTypeId": 2,
          "interestTypeDesc": "รายเดือน",
          "seqNo": 2
        }
      ],
      "paymentType": [
        {
          "paymentTypeId": 1,
          "paymentTypeDesc": "รายวัน",
          "seqNo": 1
        },
        {
          "paymentTypeId": 2,
          "paymentTypeDesc": "รายเดือน",
          "seqNo": 2
        },
        {
          "paymentTypeId": 3,
          "paymentTypeDesc": "ระบุวัน",
          "seqNo": 3
        }
      ],
      "transactionType": [
        {
          "transactionTypeId": 1,
          "transactionTypeDesc": "ทอง",
          "seqNo": 1,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 2,
          "transactionTypeDesc": "ทุน",
          "seqNo": 2,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 3,
          "transactionTypeDesc": "ออม",
          "seqNo": 3,
          "coler": '#3d5ff5'
        },
        {
          "transactionTypeId": 4,
          "transactionTypeDesc": "ความงาม",
          "seqNo": 4,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 5,
          "transactionTypeDesc": "เบอร์",
          "seqNo": 5,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 6,
          "transactionTypeDesc": "เพชร",
          "seqNo": 6,
          "coler": '#ec8b1c'
        },
        {
          "transactionTypeId": 7,
          "transactionTypeDesc": "กู้",
          "seqNo": 7,
          "coler": '#ec8b1c'
        }
      ]
    }
  }

  async save() {
    if(this.installmentid.length === 0) {
      await this.onPopup('ยังไม่ได้เลือก', false);
      return;
    }
    const cf = await this.onPopup('ยืนยัน งวดที่', true);
    if (cf) {
      let data = {
        installmentId: this.installmentid
      }
      return new Promise<any>((resolve, reject) => {
        this.service.prepaid( this.transactionId, data).subscribe(resp => {
          if (resp && resp.statusCode === 200) {
            // this.dataMain.status === 'cus' ? this.getCustomer() : this.getData();
            alert("สำเร็จ");
            this.close();
            resolve(true);
          }
        },
          async (errorRes) => {
            if (errorRes.error && errorRes.error.error) {
              await this.onPopup(errorRes.error.error, false);
              return;
            }
          });
      });
    }

  }

  
  async onPopup(message: string, flag: boolean) {
    const modalRef = this.modalService.open(PopupComfirmComponent, {
      backdrop: 'static', keyboard: false
    });
    this.setModalRef(modalRef, { message, flag });
    return modalRef.result;
  }

  installmentId(installmentId) {
    this.installmentid.push(installmentId);
  }


}
